import React from 'react';

import SEO from '@components/molecules/SEO';
import Layout from '@layouts/Layout';

const NotFoundPage = () => {
  return (
    <Layout
      translations={[]}
      currentLanguage="en"
      lang="en"
      hideParallax
      showFooter
      customClass="h-screen flex flex-col justify-between"
    >
      <SEO title={`404: Page not found`} translations={[]} />
      <h1 className="mt-0 p-20">404: Страницата не е намерена</h1>
    </Layout>
  );
};

export default NotFoundPage;
